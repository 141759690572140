import React, { ReactElement, useEffect } from 'react';
import { css } from '@emotion/react';
import { Layout } from '../components/Layout/Layout';
import { container, textCenter, paraCenter, creditBlock, creditBlockItem, creditBlockItemLink, creditBlockItemLinkContainer } from '../styles/styles';


const AICreditPage = React.memo(
  (): ReactElement => (
    useEffect(()=>{
      const deal_qstr =  window.location.href.split('?')[1];
      let links = document.getElementsByClassName("credit-link");
      if(typeof deal_qstr !== 'undefined' &&  links !== null){
        let i;
        const params = new URL(window.location.href).searchParams;
        const withEmail = ((params.get('email')) ? true : false)
        for (i = 0; i < links.length; i++) {
          if(withEmail)
            links[i].href = links[i].href + '&'  + deal_qstr + '&swp=app';
          else
            links[i].href = links[i].href + '&'  + deal_qstr;
        }
      } 
  
      }, []),
    
    <Layout>
      <div css={container}>
        <div css={textCenter}><h1 className="font-weight-3">Need More AI Credits In Your Account?</h1></div>
        <br/>
        <p  css={paraCenter} >Please select from the options below. Once your purchase is complete, you will automatically see these new AI credits added to your SketchWow software. 1 AI visual generated requires 1 AI Credit.</p>

        {/* <h2>Buy More Credits</h2> */}
        <div css={creditBlock}>
          <div css={creditBlockItem}>
                <h2>1000 Credits</h2>
                <br/><br/>
                <p>US $25.00</p>
                <small>(2 cent cost per AI visual)</small>
                <br/><br/>
                <a css={creditBlockItemLink} className='credit-link' href="https://checkout.sketchwow.com/?add-to-cart=3286277&utm_source=SW&utm_medium=app&utm_campaign=ai"><div css={creditBlockItemLinkContainer}>Add 1000 Credits</div></a>
            </div>
            <div css={creditBlockItem}>
                <h2>500 Credits</h2>
                <br/><br/>
                <p>US $15.00</p>
                <small>(3 cent cost per AI visual)</small>
                <br/><br/>
                <a css={creditBlockItemLink} className='credit-link' href="https://checkout.sketchwow.com/?add-to-cart=3152345&utm_source=SW&utm_medium=app&utm_campaign=ai"><div css={creditBlockItemLinkContainer}>Add 500 Credits</div></a>
            </div>
            <div css={creditBlockItem}>
                <h2>250 Credits</h2>
                <br/><br/>
                <p>US $10.00</p>
                <small>(4 cent cost per AI visual)</small>
                <br/><br/>
                <a css={creditBlockItemLink} className='credit-link'  href="https://checkout.sketchwow.com/?add-to-cart=3152235&utm_source=SW&utm_medium=app&utm_campaign=ai"><div css={creditBlockItemLinkContainer}>Add 250 Credits</div></a>
            </div>
        </div>

      </div>
      
    </Layout>
  ),
);

export default AICreditPage;
